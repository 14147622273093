<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Time</div>
                    <div class="card-body">
                        <b-time v-model="value" locale="en"></b-time>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Disabled and readonly states</div>
                    <div class="card-body">
                        <b-form-group label="Select time interactive state">
                            <b-form-radio-group v-model="state" aria-controls="ex-disabled-readonly">
                                <b-form-radio value="disabled">Disabled</b-form-radio>
                                <b-form-radio value="readonly">Readonly</b-form-radio>
                                <b-form-radio value="normal">Normal</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-time id="ex-disabled-readonly" :disabled="disabled" :readonly="readonly"></b-time>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">With Seconds Button</div>
                    <div class="card-body">
                        <b-time v-model="value" show-seconds locale="en"></b-time>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Border and padding</div>
                    <div class="card-body">
                        <b-time class="border rounded p-2" locale="en"></b-time>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Control Buttons</div>
                    <div class="card-body">
                        <b-time v-model="value" show-seconds locale="en">
                            <div class="d-flex" dir="ltr">
                                <b-button
                                    size="sm"
                                    variant="outline-danger"
                                    v-if="value"
                                    @click="clearTime"
                                >
                                    Clear time
                                </b-button>
                                <b-button
                                    size="sm"
                                    variant="outline-primary"
                                    class="ml-auto"
                                    @click="setNow"
                                >
                                    Set Now
                                </b-button>
                            </div>
                        </b-time>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Time',
        data() {
            return {
                value: null,
                state: 'disabled'
            }
        },
        computed: {
            disabled() {
                return this.state === 'disabled'
            },
            readonly() {
                return this.state === 'readonly'
            }
        },
        methods: {
            setNow() {
                const now = new Date()
                // Grab the HH:mm:ss part of the time string
                this.value = now.toTimeString().slice(0, 8)
            },
            clearTime() {
                this.value = ''
            }
        }
    }
</script>
